import React, {useState} from 'react';
import {Container, Row, Col, Carousel, Card} from 'react-bootstrap';
import MediaQuery, {useMediaQuery} from 'react-responsive';
import Autosuggest from 'react-autosuggest';
import {
	useQuery,
	usePageTitle,
	useHistory,
	useLocation,
	useEffect,
	useRef,
	useTranslation,
} from 'hooks';
import {queryGetHome} from 'graph';
import Loading from 'common/Loading';
import {fullPublicationId} from 'utils';
import {chunk} from 'lodash';
import Fuse from 'fuse.js';
import {Link, getEntityUrl} from 'common/Links';

/*
import {
	SlideCyberSecurity1,
	SlideCyberSecurity2
} from 'pages/home/SlideCyberSecurity'
*/

import {SlideNewGuidelines} from 'pages/home/SlideNewGuidelines';
import {SlideThemis} from 'pages/home/SlideThemis';
/*
import {
	SlideBSTalk
} from 'pages/home/SlideBSTalk'
*/
import {SlideDDBS} from 'pages/home/SlideDDBS';

//import cover from 'img/cover_01.jpg'
import placeholderCover from 'img/placeholder-cover.png';

function ProductCard({publication}) {
	const {number, slugName, title, cover} = publication;
	return (
		<Col md={3} xs={6}>
			<Card className="vds-card h-100">
				<div className="vds-card-img">
					<Link to={`/publikation/${slugName}`}>
						<img
							className="card-img-top"
							src={cover?.url || placeholderCover}
							alt={number}
						/>
					</Link>
				</div>
				<Card.Body className="h-100">
					<Link to={`/publikation/${slugName}`} className="white">
						<h5 style={{fontSize: '20px'}}>
							{fullPublicationId(publication)}
						</h5>
						<p>{title}</p>
					</Link>
				</Card.Body>
			</Card>
		</Col>
	);
}

function ProductPreview({products}) {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const responsiveChunk = () => chunk(products, isDesktopOrLaptop ? 4 : 2);
	return (
		<Row>
			<Col>
				<Carousel controls={false} indicators={false} interval={9000}>
					{responsiveChunk().map((page, index) => (
						<Carousel.Item key={index}>
							<Row className="px-1">
								{page.map((p) => (
									<ProductCard key={p.id} publication={p} />
								))}
							</Row>
						</Carousel.Item>
					))}
				</Carousel>
			</Col>
		</Row>
	);
}

function Search({publications})
{
	const {t} = useTranslation('common')
	const history = useHistory()
	const {hash} = useLocation()
	const inputRef = useRef()

	useEffect(() => {
		if (hash === '#search') {
			inputRef.current.focus();
		}
	}, [hash]);

	const fuse = new Fuse(publications, {
		keys: [
			{
				name: 'number',
				weight: 3,
			},
			{
				name: 'name',
				weight: 2,
			},
			'title',
		],
	});
	const [suggestions, setSuggestions] = useState([]);
	const [keyword, setKeyword] = useState('');

	const renderSuggestion = ({item}, {query, isHighlighted}) => {
		return (
			<div>
				<strong>{item.name}</strong> {item.title}
			</div>
		);
	};

	const onSuggestionsFetchRequested = ({value}) =>
		setSuggestions(
			fuse.search(value, {
				limit: 5,
			})
		);
	const onSuggestionsClearRequested = () => setSuggestions([]);

	const inputProps = {
		placeholder: t('search.placeholder'),
		className: 'tx-indexedsearch-searchbox-sword',
		id: 'tx-indexedsearch-searchbox-sword',
		type: 'search',
		name: 'tx_indexedsearch_pi2[search][sword]',
		value: keyword || '',
		onChange: (e) => setKeyword(e.target.value),
		ref: inputRef,
	};

	return (
		<div className="tx-indexedsearch-searchbox" style={{padding: 15}}>
			<form
				id="tx_indexedsearch"
				onSubmit={(e) => {
					e.preventDefault();
					keyword.length > 0 &&
						history.push(
							`/suche?keyword=${encodeURIComponent(keyword)}`
						);
				}}
			>
				<div className="row" style={{marginBottom: 0}}>
					<div className="tx-indexedsearch-form col">
						<Autosuggest
							suggestions={suggestions}
							onSuggestionsFetchRequested={
								onSuggestionsFetchRequested
							}
							onSuggestionsClearRequested={
								onSuggestionsClearRequested
							}
							onSuggestionSelected={(
								e,
								{suggestion, suggestionValue}
							) => history.push(getEntityUrl(suggestion.item))}
							getSuggestionValue={({item}) => item.title}
							renderSuggestion={renderSuggestion}
							inputProps={inputProps}
						/>
					</div>
					<div className="tx-indexedsearch-search-submit col-auto">
						<button
							className="tx-indexedsearch-searchbox-button"
							id="tx-indexedsearch-searchbox-button-submit"
							type="submit"
						/>
					</div>
				</div>
			</form>
		</div>
	);
}


export default function Home()
{
	const {t} = useTranslation('home');
	const {data, loading} = useQuery(queryGetHome)
	usePageTitle('Startseite')

	if (loading) {
		return <Loading />;
	}

	return (
		<Container as="section">
			<Row>
				<Col>
					<div className="frame">
						<MediaQuery minDeviceWidth={1024}>
							<Carousel
								className="main-carousel"
								controls={false}
							>
								{/*
								<Carousel.Item>
									<SlideCyberSecurity1/>
								</Carousel.Item>
								<Carousel.Item>
									<SlideCyberSecurity2/>
								</Carousel.Item>
								<Carousel.Item>
									<SlideBSTalk />
								</Carousel.Item>
								*/}
								<Carousel.Item>
									<SlideDDBS />
								</Carousel.Item>
								<Carousel.Item>
									<SlideNewGuidelines />
								</Carousel.Item>
								<Carousel.Item>
									<SlideThemis />
								</Carousel.Item>
							</Carousel>
						</MediaQuery>
						<div
							className="tx-indexedsearch-searchbox"
							style={{padding: 15}}
						>
							<Search
								publications={[
									...data.publications,
									...data.collections,
								]}
							/>
						</div>
					</div>
				</Col>
			</Row>
			{/*
			<Row className="mt-4">
				<Col>
					<div className="card vds-card">
        				<div className="card-body">
          					<h2 className="text-white">Hinweis</h2>
          					<p className="text-white">Der VdS-Verlag bleibt in der Zeit vom 18. Dezember 2024 bis einschließlich 3. Januar 2025 geschlossen. Unser Shop steht Ihnen jedoch weiterhin für Bestellungen und Downloads zur Verfügung.</p>
            				<p className="text-white"><strong>Bitte beachten Sie folgende Einschränkungen:</strong> Neue Registrierungen und manuell zu bearbeitende Aufträge können erst im Januar durchgeführt werden. Ab Montag, den 6. Januar 2025, sind wir wieder persönlich für Sie da.</p>
            				<p className="text-white">Wir wünschen Ihnen eine besinnliche Zeit und ein frohes Neues Jahr!</p>
            			</div>
          			</div>
				</Col>
			</Row>
			*/}
			<Row className="mt-4">
				<Col>
					<h1 className="text-center">
						{t('headline-bs')}
					</h1>
				</Col>
			</Row>
			<ProductPreview products={data.recentPublicationsFireprotection} />
			<Row className="mt-4">
				<Col>
					<h1 className="text-center">
						{t('headline-sec')}
					</h1>
				</Col>
			</Row>
			<ProductPreview products={data.recentPublicationsSecurity} />
		</Container>
	);
}
